<template>
  <!-- <test-page ref="testPage"  @afterSaveAnswer="afterSaveAnswer" @backHome="backHome"></test-page> -->
  <b-overlay
    :show="showLoading"
    rounded="lg"
    :variant="variant"
    :opacity="opacity"
  >
    <b-container>
      <div class="listening-test">
        <b-row align-v="center" class="test-title-box">
          <b-col md="7">
            <span class="simulation-test-title">{{
              testData["examName"]
            }}</span>
            <span class="test-subtitle">{{
              "（满分：" + testData["score"] + " 分）"
            }}</span>
          </b-col>
          <b-col>
            <b-button
              :disabled="isLoading"
              @click="startPlayingPpopup"
              class="begin-button"
              v-if="voiceState === 0"
            >
              <span class="iconfont iconbofang"></span>
              开始播放
            </b-button>
            <b-button disabled v-if="voiceState === 1">
              <!-- <span class="iconfont iconlaba-fill"></span> -->
              <img src="../../assets/images/laba.gif" alt="" />
              正在播放
            </b-button>
            <b-button class="endOfPlay" disabled v-if="voiceState === 2">
              <span class="iconfont iconduihao2"></span>
              播放结束
            </b-button>
            <video
              v-show="false"
              id="winVideo"
              class="azuremediaplayer amp-default-skin amp-big-play-centered"
            ></video>
            <span
              style="text-align: right; cursor: pointer; padding: 0rem 2rem"
            >
              <a @click="bacToHomeButton" style="text-decoration: underline"
                >返回首页</a
              >
            </span>
          </b-col>
          <GlobalPopup
            :popupsTitle="`确定开始播放听力录音？确定后播音将不可中断，直至测试结束！`"
            :popupsbuttomA="`再想想`"
            :popupsbuttomB="`现在播放`"
            @OKbutton="startPlayButton"
            @NObutton="
              () => {
                modal1 = false;
              }
            "
            :isShow="modal1"
          ></GlobalPopup>
          <GlobalPopup
            :popupsTitle="`你正在离开答题区，离开后下次进入将重新开始测试！`"
            :popupsbuttomA="`坚决离开`"
            :popupsbuttomB="`继续测试`"
            @NObutton="backHome"
            @OKbutton="
              () => {
                modal2 = false;
              }
            "
            :isShow="modal2"
          ></GlobalPopup>
        </b-row>
        <b-row style="text-align: left">
          <b-col cols="10" class="test-prompt">
            点击【开始播放】，将正式开始听力测试！学生可提前提交答案；系统到时将自动提交。
          </b-col>
        </b-row>
        <b-row
          class="group-content"
          v-for="(item, index) in testData.sectionExamQuestions"
          :key="item.sectionId"
          style="text-align: left"
        >
          <b-col>
            <b-row class="row-content">
              <b-col>
                <div class="group-stament">{{ item.statement }}</div>
              </b-col>
            </b-row>
            <b-row
              class="row-content"
              v-for="question in item.examQuestions"
              :key="question.questionId"
            >
              <b-col :class="index == 0 ? 'testQuestions' : ''">
                <b-row class="question-stament">
                  <b-col>{{
                    question.variation +
                    ". " +
                    (question.statement ? question.statement : "")
                  }}</b-col>
                </b-row>
                <b-col class="question-options">
                  <b-form-radio-group
                    style="display: flex; flex-wrap: wrap"
                    size="lg"
                    v-model="studentAnswers[question.questionId]"
                    @change="changeRadio"
                  >
                    <b-col
                      md="4"
                      cols="12"
                      v-for="option in question.options"
                      :key="option.value"
                      class="question-option"
                    >
                      <b-form-radio class="enter-radio" :value="option.value"
                        ><span>{{
                          option.value + ". " + option.statement
                        }}</span></b-form-radio
                      >
                    </b-col>
                  </b-form-radio-group>
                </b-col>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="submiAnswer">
            <b-button class="submit-button" @click="submitNowAnswer" size="lg">
              <span class="iconfont iconsubmit-fill"></span>
              <span>提交答案</span>
            </b-button>
            <div>
              <countdown @afterCountdown="afterCountdown"></countdown>
            </div>
            <span class="countdownReminder"
              >（听力播放结束后，系统将启动60秒倒计时供你检查答案，到时自动提交）</span
            >
          </b-col>
          <GlobalPopup
            :popupsTitle="`确定现在提交答案吗？提交后将无法修改！`"
            :popupsbuttomA="`再想想`"
            :popupsbuttomB="`现在提交`"
            @OKbutton="submitAnswerButton"
            @NObutton="
              () => {
                modal3 = false;
              }
            "
            :isShow="modal3"
          ></GlobalPopup>
        </b-row>
      </div>
    </b-container>
  </b-overlay>
</template>

<script>
// import TestPage from './components/test-page'
import { getExamQuestion, saveAnswer } from "@/api/english-mock";
import { mapMutations } from "vuex";
import GlobalPopup from "@/components/global-popup/global-popup-modal.vue";
import countdown from "@/components/countdown/countdown.vue";
var myPlayer;
export default {
  data() {
    return {
      testData: "",
      studentAnswers: "",
      voiceState: 0,
      voiceUrl: "",
      showLoading: false,
      variant: "white",
      opacity: 1,
      popupsTitle: "",
      popupsbuttomA: "再想想",
      popupsbuttomB: "现在播放",
      componentPopupId: 0,
      modal1: false,
      modal2: false,
      modal3: false,
      isSubmitTest: false,
      isLoading: true,
      leaveIllegally: false,
      toName: "",
    };
  },
  components: {
    GlobalPopup,
    countdown,
  },
  mounted() {
    this.getTestData();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.leaveIllegally) {
      this.toName = to.name;
      console.log("to.path", to.path);
      if (to.path == "/login") {
        return (this.leaveIllegally = true);
      } else {
        this.modal2 = true;
        return this.leaveIllegally;
      }
    }
    // this.$refs.testPage.stopVoice();
    this.stopVoice();

    this.setListeningAnswers(null);
    this.setTestEndTime(null);
    sessionStorage.setItem("listeningBeginTime", null);
    next();
  },
  methods: {
    ...mapMutations([
      "setListeningTime",
      "setListeningAnswers",
      "setTestEndTime",
    ]),
    afterSaveAnswer() {
      this.$router.replace({
        name: "MockPrompt",
        query: {
          id: this.$route.query.id,
          state: 1,
          isPass: this.$route.query.isPass,
        },
      });
    },
    backHome() {
      this.leaveIllegally = true;
      if (this.toName) {
        this.$router.replace({
          name: this.toName,
        });
      } else {
        if (this.$route.query.isPass) {
          this.$router.replace({
            name: "MockBegin",
            query: {
              isPass: this.$route.query.isPass,
            },
          });
        } else {
          this.$router.replace({
            name: "MockBegin",
          });
        }
      }
    },
    startPlayingPpopup() {
      this.modal1 = true;
    },
    bacToHomeButton() {
      this.modal2 = true;
      myPlayer.pause();
      this.voiceState = 0;
    },
    // 倒计时
    afterCountdown() {
      this.submitAnswer();
    },
    submitNowAnswer() {
      this.modal3 = true;
      myPlayer.pause();
      this.voiceState = 0;
    },
    // 确定播放按钮
    startPlayButton() {
      let currentTime = 0;
      const a = parseInt(sessionStorage.getItem("voiceTime"));
      if (a > 0) {
        currentTime = a;
      }
      console.log("测试音频时间", currentTime);
      sessionStorage.setItem("voiceTime", 0);
      myPlayer.currentTime(currentTime);
      myPlayer.play();
      this.voiceState = 1;
      if (
        sessionStorage.getItem("listeningBeginTime") == "null" ||
        !sessionStorage.getItem("listeningBeginTime")
      ) {
        sessionStorage.setItem("listeningBeginTime", new Date().getTime());
      }
      this.modal1 = false;
    },
    playThinkAgain() {
      this.modal1 = false;
    },
    // 提交按钮
    submitAnswerButton() {
      this.submitAnswer();
    },
    againAnswerButton() {
      this.modal3 = false;
    },
    stopVoice() {
      myPlayer.pause();
      sessionStorage.setItem("voiceTime", null);
    },
    voiceInit() {
      var myOptions = {
        nativeControlsForTouch: false,
        controls: true,
        autoplay: false,
      };
      const self = this;
      myPlayer = window.amp("winVideo", myOptions, () => {
        self.isLoading = false;
      });
      myPlayer.src([
        {
          src: this.voiceUrl,
          type: "application/vnd.ms-sstr+xml",
          dataSetup: {
            streamingFormats: ["DASH", "SMOOTH", "HLS-V3", "HLS-V4"],
          },
        },
      ]);

      myPlayer.addEventListener("ended", function () {
        self.voiceState = 2;
        self.setTestEndTime(new Date().getTime() + 60000);
      });

      myPlayer.addEventListener("timeupdate", function () {
        const currentTime = Math.floor(myPlayer.currentTime());
        if (
          sessionStorage.getItem("voiceTime") !== "null" &&
          sessionStorage.getItem("voiceTime")
        ) {
          sessionStorage.setItem("voiceTime", currentTime);
        }
      });
    },
    changeRadio(checked) {
      console.log("用户选择答案", checked);
      this.setListeningAnswers(this.studentAnswers);
    },
    getTestData() {
      this.showLoading = true;
      const parmeter = {
        examId: this.$route.query.id,
      };
      getExamQuestion(parmeter).then((res) => {
        console.log("res", res);
        this.showLoading = false;
        this.testData = res.data.result;
        this.voiceUrl = res.data.result.voiceUrl;
        // this.setTestEndTime(new Date().getTime() + 1800000);
        this.getAnswerList(res.data.result.sectionExamQuestions);
        this.voiceInit();
      });
    },
    getAnswerList(sectionExamQuestions) {
      debugger;
      if (this.listeningAnswers) {
        this.studentAnswers = this.listeningAnswers;
        return;
      }
      const answerList = {};
      for (const a of sectionExamQuestions) {
        for (const b of a.examQuestions) {
          answerList[b.questionId] = "";
        }
      }
      this.studentAnswers = answerList;
      this.setListeningAnswers(this.studentAnswers);
      console.log("学生答案列表", this.studentAnswers);
    },
    submitAnswer() {
      debugger;
      this.leaveIllegally = true;
      if (this.isSubmitTest) return;
      this.isSubmitTest = true;
      let minutes = 0;
      if (parseInt(sessionStorage.getItem("listeningBeginTime")) > 0) {
        minutes =
          new Date().getTime() - sessionStorage.getItem("listeningBeginTime");
      }
      const parmeter = {
        examId: this.$route.query.id,
        answerMinutes: parseInt(minutes / 1000 / 60),
        mode: this.getMode(),
        answerInputs: this.getAnswerInputs(),
      };
      saveAnswer(parmeter).then((res) => {
        this.afterSaveAnswer(res.data.result.studentExamId);
        this.isSubmitTest = false;
      });
    },
    getMode() {
      if (this.$route.query.isPass) {
        return "X";
      }
      return "C";
    },
    getAnswerInputs() {
      const list = [];
      for (const i in this.studentAnswers) {
        list.push({
          questionId: i,
          answer: this.studentAnswers[i],
        });
      }
      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
.begin-button {
  background-image: linear-gradient(#fbb860, #fbab43);
}
.submit-button {
  background-image: linear-gradient(#fbb860, #fbab43);
}
.listening-test {
  min-height: 100vw;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 4rem 0;
  letter-spacing: 1px;
  .btn-secondary {
    border-color: #fbb860;
    img {
      width: 20%;
    }
  }
  .endOfPlay {
    color: #666;
    border-color: #e5e5e5;
    background-color: #e5e5e5;
  }
}
.test-title-box {
  border-bottom: 1px solid #e5e5e5;
  padding: 0 0 1rem;
  margin: 0 1rem;
  text-align: left;
  line-height: 2;
  & > div:nth-child(2) {
    text-align: right;
  }
}
.simulation-test-title {
  font-size: 2rem;
  font-weight: bold;
}
.test-subtitle {
  font-size: 2rem;
  font-weight: bold;
}
.test-prompt {
  font-size: 0.8vw;
  color: #2cb774;
  padding: 1.5rem 2rem;
  font-weight: bold;
  text-indent: 1rem;
}
.question-options {
  text-indent: 0rem;
  padding-left: 6rem;
}
.question-stament {
  padding: 0.5rem 0;
  text-indent: 2rem;
  font-weight: bold;
}
.question-option {
  padding: 0.5rem 0;
  .custom-control-inline {
    padding-left: 0 !important;
    margin-right: 0 !important;
    min-height: 1.35rem;
    font-size: 1rem !important;
    line-height: 2 !important;
  }
}
.group-content {
  border-bottom: 5px solid #f0f0f0;
  padding: 0 0 2rem;
  margin: 0 0 2rem;
  line-height: 1.5;
  text-indent: 3%;
  .group-stament {
    font-size: 1.1571rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-indent: -28px;
    line-height: 2.5;
    margin-left: 30px;
  }
}
.enter-radio
  ::v-deep
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #2cb774;
  background-color: #2cb774;
}
.iconduihao2 {
  color: #f7a234;
}
.submiAnswer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > div:nth-child(2) {
    padding: 0rem 1rem;
  }
}
.row-content {
  padding: 1rem 0rem;
}
.testQuestions {
  display: flex;
  align-items: center;
  .question-options {
    text-indent: 0rem;
    padding-left: 2.5rem;
  }
}
.countdownReminder {
  color: #2cb774;
  font-weight: bold;
  font-size: 12px;
}
.iconsubmit-fill {
  font-size: 12px;
  padding-right: 0.4rem;
  font-size: 18px;
}
</style>

